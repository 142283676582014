import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import AuthRoutes from './services/AuthRoutes';
import GlobalContext from './contexts/GlobelContext'; // Correct import
import Dashboard from './components/Dashboard';
import Notfound from './components/Notfound/Notfound';
import PanNormal from './components/pages/pan-normal';
import PanAdvance from './components/pages/pan-advance';
import RcNormal from './components/pages/rc-normal';
import RcAdvance from './components/pages/rc-advance';
import AioAadharVerify from './components/pages/aio-aadhar-verify';
import AioAadharOtp from './components/pages/aio-aadhar-otp';
import AioOfflineAadharVerify from './components/pages/offline-aadhar-verify';
import BankVerification from './components/pages/bank-verification';
import ChangePassword from './components/pages/change-password';
import ForgotPassword from './components/pages/forgot-password';
import ResetPassword from './components/pages/reset-password';
import GstNormal from './components/pages/gst-normal';
import DrivingLicence from './components/pages/driving-licence';
import VoteridAdvance from './components/pages/voterid-advance';
import OfflinePan from './components/pages/offline-pan-verify';
import ChallanAdvance from './components/pages/challan-advance';
import RcReverse from './components/pages/rc-reverse';
import RcHistory from './components/pages/rc-history';

function App() {
  const { isUserAuth, setIsUserAuth } = useContext(GlobalContext);

  useEffect(() => {
    if (localStorage.getItem('loggedInUser') === 'true') {
      setIsUserAuth(true);
    } else {
      setIsUserAuth(false);
    }
  }, [setIsUserAuth]);

  console.log(isUserAuth);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AuthRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/pan-normal" element={<PanNormal/>} />
          <Route path="/pan-advance" element={<PanAdvance/>} />
          <Route path="/rc-normal" element={<RcNormal/>} />
          <Route path="/rc-advance" element={<RcAdvance/>} />
          <Route path="/aio-aadhar-otp" element={<AioAadharOtp/>} />
          <Route path="/aio-aadhar-verify" element={<AioAadharVerify/>} />
          <Route path="/offline-aadhaar" element={<AioOfflineAadharVerify/>} />
          <Route path="/bank-verification" element={<BankVerification/>} />
          <Route path="/gst-normal" element={<GstNormal/>} />
          <Route path="/voterid-advance" element={<VoteridAdvance/>} />
          <Route path="/offline-pan" element={<OfflinePan/>} />
          <Route path="/driving-licence" element={<DrivingLicence/>} />
          <Route path="/change-password" element={<ChangePassword/>} />
          <Route path="/challan-advance" element={<ChallanAdvance/>} />
          <Route path="/rc-reverse" element={<RcReverse/>} />
          <Route path="/rc-history" element={<RcHistory/>} />
          <Route path="*" element={<Notfound />} />
        </Route>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </Router>
  );
} 

export default App;
