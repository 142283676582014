import React, { useState, useEffect,useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";

const Pan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [pan, setPan] = useState("");
    const [name, setName] = useState("");
    const [panDetails, setPanDetails] = useState();
    const handleCancel = () => {
        // Reset the form
        formRef.current.reset();
        setPan("");
        setPanDetails(null);
    }
    let aadhaar_linked='';

    const panFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "pan_number":pan,
            "name":name
        };
        CommonService.panSearchAdvance(data)
        .then((response) => {
            if(response.data.status === 200){
                setPanDetails(response.data);
                setIsLoading(false);
                if(response.data.data.aadhaar_linked === true){
                    aadhaar_linked="True";
                }
            }else{
                
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
                setPanDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data.data);
            if(error.response.data.status === 510){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setPanDetails(null);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.message,
                })
                setIsLoading(false);
                setPanDetails(null);
            }
        });
    }

   

                                                                    
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">PAN Advance Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ panFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Pan</label>
                                                        <input type="text" defaultValue={pan}
                                                            onChange={(e) => { setPan(e.target.value) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter pan number"
                                                            maxLength={10} 
                                                            />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Name</label>
                                                        <input type="text" defaultValue={name}
                                                            onChange={(e) => { setName(e.target.value) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter name"
                                                            
                                                            />
                                                    </div>
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { panDetails ? (
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-user-check me-1'></i> PAN Details
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>PAN Number</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.data.data.pan ? panDetails.data.data.pan : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Pan Holder Title</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.data.data.registered_name ? panDetails.data.data.registered_name : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Name</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.data.data.name_pan_card ? panDetails.data.data.name_pan_card : 'NA'}
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Date of Birth</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {panDetails.data.data.date_of_birth ? panDetails.data.data.date_of_birth : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Name Provided</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.data.data.name_provided ? panDetails.data.data.name_provided : 'NA'}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Mobile</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {panDetails.data.data.mobile_number ? panDetails.data.data.mobile_number : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Gender</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {panDetails.data.data.gender ? panDetails.data.data.gender : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Aadhaar Seeding Status</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right", color: 'green'}}>
                                                                    {aadhaar_linked ? aadhaar_linked : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Aadhaar Seeding No.</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right", color: 'green'}}>
                                                                    {panDetails.data.data.masked_aadhaar_number ? panDetails.data.data.masked_aadhaar_number : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Email</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {panDetails.data.data.email ? panDetails.data.data.email : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b> Pan Status</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right", color: panDetails.data.data.status === 'VALID' ? 'green' : 'red' }}>
                                                                    {panDetails.data.data.status ? panDetails.data.data.status : 'NA'}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Address</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.data.data.address.full_address ? panDetails.data.data.address.full_address : 'NA'}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Type</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.data.data.type ? panDetails.data.data.type : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i> API Status
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                <b> Request Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.data.requestTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Response Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.data.responseTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                <b>Latency(sec)</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.data.totalTime}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : ( '' )}   
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default Pan;