import React, { useState, useEffect,useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";
import moment from 'moment';

const RcHistory = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [rc, setRc] = useState("");
    const [rcDetails, setRcDetails] = useState();
    const [showAll, setShowAll] = useState(false);
    const toggleShowAll = () => {
        setShowAll(!showAll);
    };
    const handleCancel = () => {
        // Reset the form
        formRef.current.reset();
        setRc("");
        setRcDetails(null);
    }

    const rcFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "request_rc_number":rc
        };
        CommonService.rcSearchHistory(data)
        .then((response) => {
            
            if(response.data.status === 200){
                setRcDetails(response.data.data);
                setIsLoading(false);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }
        })
        .catch((error) => {
            if(error.response.data.status === 400){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.data.error.type,
                    text: error.response.data.data.error.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }
           
         
        });
    }
    
    if(rcDetails !== undefined && rcDetails !== null){
        var displayedFeeDetails = showAll ? rcDetails.data.feeCollectionDetails : rcDetails.data.feeCollectionDetails.slice(0, 1);
    }
    

    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">RC History Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ rcFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Rc No.</label>
                                                        <input type="text" defaultValue={rc}
                                                            onChange={(e) => { setRc(e.target.value.toUpperCase()) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter rc number"
                                                         />
                                                    </div>
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { rcDetails ? (
                                                <>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-truck me-1'></i> RC DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body" style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>RC Registration Number</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.regNo ? rcDetails.data.regNo : 'NA'}
                                                                </div>
                                                            </div>
                                                             <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Owner Name</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.ownerName ? rcDetails.data.ownerName : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Father Name</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.fathersHusbandsName ? rcDetails.data.fathersHusbandsName : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Address</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.address ? rcDetails.data.address : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                   <b>Registered RTO</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.registeredWithRto ? rcDetails.data.registeredWithRto : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                   <b>Vehicle Status</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.vehicleStatus ? rcDetails.data.vehicleStatus : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                            <i className="menu-icon tf-icons ti ti-report-money me-1"></i> FINANCE & INSURANCE DETAILS
                                                            </p>
                                                        </ul>
                                                        {
                                                            rcDetails.data.insuranceDetails.map((insuranceVal, index) => (
                                                            <div key={index} className="card-body" style={{ paddingTop: "0px" }}>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Sr. No.</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {insuranceVal.sNo ? insuranceVal.sNo : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Insurance Company</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {insuranceVal.insuranceCompany ? insuranceVal.insuranceCompany : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Insurance Type</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {insuranceVal.insuranceType ? insuranceVal.insuranceType : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Insurance From</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {insuranceVal.insuranceFrom ? insuranceVal.insuranceFrom : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Insurance To</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {insuranceVal.insuranceUpto ? insuranceVal.insuranceUpto : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Cover Note No.</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {insuranceVal.coverNoteNo ? insuranceVal.coverNoteNo : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Office</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {insuranceVal.office ? insuranceVal.office : 'NA'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                            <i className="menu-icon tf-icons ti ti-users me-1"></i>  OWNERS DETAILS
                                                            </p>
                                                        </ul>
                                                        {
                                                            rcDetails.data.allOwnerDetails.map((ownerVal, index) => (
                                                            <div key={index} className="card-body" style={{ paddingTop: "0px" }}>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Sr. No.</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {ownerVal.sNo ? ownerVal.sNo : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Registration No</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {ownerVal.registrationNo ? ownerVal.registrationNo : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Owner name</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {ownerVal.ownerName ? ownerVal.ownerName : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Father Name</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {ownerVal.fatherName ? ownerVal.fatherName : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Address</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {ownerVal.address ? ownerVal.address : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Status</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {ownerVal.status ? ownerVal.status : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Moved On</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {ownerVal.movedOn ? ownerVal.movedOn : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Moved By</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {ownerVal.movedBy ? ownerVal.movedBy : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Office Name</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {ownerVal.officeName ? ownerVal.officeName : 'NA'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i> VEHICLE DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Vehicle Class</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.vehicleClass ? rcDetails.data.vehicleClass : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  >
                                                                <div className="col-md-6" >
                                                                    <b>Chasis Number</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {rcDetails.data.chasisNo ? rcDetails.data.chasisNo : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Horse Power</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                {rcDetails.data.horsePower ? rcDetails.data.horsePower : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Color</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.color ? rcDetails.data.color : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Engine Number</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.engineNo ? rcDetails.data.engineNo : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Body Type</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.bodyType ? rcDetails.data.bodyType : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Make Model</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.makerModel ? rcDetails.data.makerModel : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Fuel Type</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.fuel ? rcDetails.data.fuel : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Seating Capacity</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.seatingCapacity ? rcDetails.data.seatingCapacity : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Standing Capacity</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.standingCapacity ? rcDetails.data.standingCapacity : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Unladen Weight (Kg)</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.unladenWeightKg ? rcDetails.data.unladenWeightKg : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Laden Weight (Kg)</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.ladenWeightKg ? rcDetails.data.ladenWeightKg : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}> 
                                                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-tax"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M8.487 21h7.026a4 4 0 0 0 3.808 -5.224l-1.706 -5.306a5 5 0 0 0 -4.76 -3.47h-1.71a5 5 0 0 0 -4.76 3.47l-1.706 5.306a4 4 0 0 0 3.808 5.224" /><path d="M15 3q -1 4 -3 4t -3 -4z" /><path d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" /><path d="M12 10v1" /><path d="M12 17v1" /></svg> TAX DETAILS
                                                            </p>
                                                        </ul>
                                                        {
                                                            rcDetails.data.taxDetails.map((taxVal, index) => (
                                                            <div key={index} className="card-body" style={{ paddingTop: "0px" }}>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Sr. No.</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {taxVal.sNo ? taxVal.sNo : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Receipt No</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {taxVal.receiptNo ? taxVal.receiptNo : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Tax From</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {taxVal.taxFrom ? taxVal.taxFrom : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Tax To</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {taxVal.taxUpto ? taxVal.taxUpto : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Tax Type</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {taxVal.taxType ? taxVal.taxType : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Challan Date</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {taxVal.challanDate ? taxVal.challanDate : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Tax Amount</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        <i class="ti ti-currency-rupee" style={{fontSize:"18px"}}></i>{taxVal.taxAmount ? taxVal.taxAmount : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Fine Penalty</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        <i class="ti ti-currency-rupee" style={{fontSize:"18px"}}></i>{taxVal.finePenalty ? taxVal.finePenalty : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Office</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {taxVal.office ? taxVal.office : 'NA'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-cash-register"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M21 15h-2.5c-.398 0 -.779 .158 -1.061 .439c-.281 .281 -.439 .663 -.439 1.061c0 .398 .158 .779 .439 1.061c.281 .281 .663 .439 1.061 .439h1c.398 0 .779 .158 1.061 .439c.281 .281 .439 .663 .439 1.061c0 .398 -.158 .779 -.439 1.061c-.281 .281 -.663 .439 -1.061 .439h-2.5" /><path d="M19 21v1m0 -8v1" /><path d="M13 21h-7c-.53 0 -1.039 -.211 -1.414 -.586c-.375 -.375 -.586 -.884 -.586 -1.414v-10c0 -.53 .211 -1.039 .586 -1.414c.375 -.375 .884 -.586 1.414 -.586h2m12 3.12v-1.12c0 -.53 -.211 -1.039 -.586 -1.414c-.375 -.375 -.884 -.586 -1.414 -.586h-2" /><path d="M16 10v-6c0 -.53 -.211 -1.039 -.586 -1.414c-.375 -.375 -.884 -.586 -1.414 -.586h-4c-.53 0 -1.039 .211 -1.414 .586c-.375 .375 -.586 .884 -.586 1.414v6m8 0h-8m8 0h1m-9 0h-1" /><path d="M8 14v.01" /><path d="M8 17v.01" /><path d="M12 13.99v.01" /><path d="M12 17v.01" /></svg>&nbsp; FEE COLLECTION DETAILS
                                                            </p>
                                                        </ul>
                                                        {
                                                            displayedFeeDetails.map((feeVal, index) => (
                                                            <div key={index} className="card-body" style={{ paddingTop: "0px" }}>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Sr. No.</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {feeVal.sNo ? feeVal.sNo : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Receipt No</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {feeVal.receiptNo ? feeVal.receiptNo : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Receipt Date</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {feeVal.receiptDate ? feeVal.receiptDate : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Fee Amount</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        <i class="ti ti-currency-rupee" style={{fontSize:"18px"}}></i>{feeVal.feeAmount ? feeVal.feeAmount : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Fine</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {feeVal.fine ? feeVal.fine : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>Fee Particular</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {feeVal.feeParticular ? feeVal.feeParticular : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{ background: '#ebe9e9' }}>
                                                                    <div className="col-md-6">
                                                                        <b>Office</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {feeVal.office ? feeVal.office : 'NA'}
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        ))}
                                                        {rcDetails.data.feeCollectionDetails.length > 1 && (
                                                            <div style={{ textAlign: "center", marginBottom: "10px", width: "100%" }}>
                                                                <button
                                                                    className="btn btn-outline-info waves-effect"
                                                                    onClick={toggleShowAll}
                                                                    style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                                                >
                                                                    <span>{showAll ? 'Show Less' : 'Show More'}</span>
                                                                    <span>{showAll ? <i className="ti ti-square-chevron-up"></i> : <i className="ti ti-square-chevron-down"></i>}</span>
                                                                </button>
                                                            </div>
                                                            
                                                        )}
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-clock-12"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 0 0 9 9m9 -9a9 9 0 1 0 -18 0" /><path d="M12 7v5l.5 .5" /><path d="M18 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2" /><path d="M15 21v-6" /></svg> &nbsp; API HEALTH CHECK
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b> Request Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.requestTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Response Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.responseTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Latency(sec)</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.totalTime}
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>    
                                                </div>
                                             </>
                                            ) : ( '' )}   
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default RcHistory;