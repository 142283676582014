import React, { useState, useEffect,useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";
import moment from 'moment';

const Challan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [rc, setRc] = useState("");
    const [challanDetails, setChallanDetails] = useState();
    const [expandedIndex, setExpandedIndex] = useState(null);
    const handleCancel = () => {
        // Reset the form
        formRef.current.reset();
        setRc("");
        setChallanDetails(null);
    }

    const challanFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "request_digital_no":rc
        };
        CommonService.challanSearchAdvance(data)
        .then((response) => {
            
            if(response.data.status === 200 && response.data.data.data.length > 0){
                if(response.data.data.data[0].status===404){
                    Swal.fire({
                        icon: 'error',
                        title: "Not Found!",
                        text: "E-challan not available in this RC Number",
                    })
                    setIsLoading(false);
                    setChallanDetails(null);
                }else{
                    setChallanDetails(response.data.data);
                    setIsLoading(false);
                }
                
            }else if(response.data.data.data.length === 0){
                Swal.fire({
                    icon: 'error',
                    title: "Not Found!",
                    text: "E-challan not available in this RC Number",
                })
                setIsLoading(false);
                setChallanDetails(null);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
                setChallanDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data.data);
            if(error.response.data.status === 510){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setChallanDetails(null);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setChallanDetails(null);
            }
        });
    }

    const toggleExpanded = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index); 
    };
  
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">RC Echallan Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ challanFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Rc Number</label>
                                                        <input type="text" defaultValue={rc}
                                                            onChange={(e) => { setRc(e.target.value.toUpperCase()) }} required data-parsley-required="true" maxLength={10}
                                                            className="form-control" placeholder="Enter rc number"
                                                         />
                                                    </div>
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { challanDetails ? (
                                                <>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-truck me-1'></i>E-CHALLAN DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row">
                                                            { challanDetails.data.map((challVal, index) => (
                                                                    <>
                                                                    <div className="col-md-6" key={index} >
                                                                        <div className="row" style={{padding:"15px", margin:"2px", border:"1px solid", borderRadius:"11px"}}>
                                                                            <div className="col-md-6" style={{background: '#ebe9e9'}}>
                                                                                <b>Challan No.</b> 
                                                                            </div>
                                                                            <div className="col-md-6"  style={{textAlign:"right", background: '#ebe9e9'}}>
                                                                                <b>RC Number</b>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                {challVal.challanNo}
                                                                            </div>
                                                                            <div className="col-md-6" style={{textAlign:"right"}}>
                                                                                {challVal.dlRcNumber}
                                                                            </div>
                                                                   
                                                                            <div className="col-md-6"style={{background: '#ebe9e9'}} >
                                                                                <b>Challan Date</b>
                                                                            </div>
                                                                            <div className="col-md-6"  style={{textAlign:"right", background: '#ebe9e9'}}>
                                                                                <b>Payment Date</b> 
                                                                            </div>
                                                                        
                                                                            <div className="col-md-6">
                                                                                { challVal.dateChallan }
                                                                            </div>
                                                                            <div className="col-md-6" style={{textAlign:"right"}}>
                                                                              { challVal.datePayment }
                                                                            </div>
                                                                            {expandedIndex === index && (
                                                                                <>
                                                                                    <div className="col-md-6" style={{background: '#ebe9e9' }}>
                                                                                        <b>Violator Name</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right", background: '#ebe9e9' }}>
                                                                                        <b>Violator Father Name</b>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        {challVal.nameViolator}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{textAlign: "right"}}>
                                                                                         {challVal.violatorFatherName}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ background: '#ebe9e9' }}>
                                                                                        <b>Challan Amount</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right", background: '#ebe9e9' }}>
                                                                                        <b>Challan Status</b>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <i class="ti ti-currency-rupee" style={{fontSize:"18px"}}></i>{challVal.amountChallan}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                        {challVal.status}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ background: '#ebe9e9' }}>
                                                                                        <b>Challan Location</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right", background: '#ebe9e9' }}>
                                                                                        <b>State</b>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        {challVal.locationChallan}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                        {challVal.State}
                                                                                    </div>
                                                                                    <h5 style={{paddingLeft: "0px"}} >Violation Details</h5>
                                                                                    { 
                                                                                        challVal.detailsViolation.map((vioVal, index) => (
                                                                                        <table style={{margin:"10px 0px 10px 0px"}}>
                                                                                            <p>S.No. {index+1}</p>
                                                                                            <tbody>
                                                                                                <tr style={{border: "1px solid"}}>
                                                                                                    <td style={{width:"84px", background:"#7367f094", color:"#fff", padding:"10px"}}>Offence</td>
                                                                                                    <td style={{padding:"10px"}}>{vioVal.offence}</td>
                                                                                                </tr>
                                                                                                <tr style={{border: "1px solid"}}>
                                                                                                    <td style={{width:"84px", background:"#7367f094", color:"#fff", padding:"10px"}}>Penalty</td>
                                                                                                    <td style={{padding:"10px"}}>{vioVal.penalty}</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    ))}
                                                                                    <div className="col-md-6" style={{ background: '#ebe9e9' }}>
                                                                                        <b>Engine No.</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right", background: '#ebe9e9' }}>
                                                                                        <b>Chassis No.</b>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        {challVal.noEngine}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                        {challVal.noChassis}
                                                                                    </div>

                                                                                    <div className="col-md-6" style={{ background: '#ebe9e9' }}>
                                                                                        <b>Owner Name</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right", background: '#ebe9e9' }}>
                                                                                        <b>Owner Father Name</b>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        {challVal.nameOwner}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                        {challVal.nameFatherOwner}
                                                                                    </div>

                                                                                    <div className="col-md-6" style={{ background: '#ebe9e9' }}>
                                                                                        <b>Owner Address</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right", background: '#ebe9e9' }}>
                                                                                        <b>Image</b>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        {challVal.addressOwner}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                        {challVal.imageVehicle}
                                                                                    </div>

                                                                                    <div className="col-md-6" style={{ background: '#ebe9e9' }}>
                                                                                        <b>Mobile No.</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right", background: '#ebe9e9' }}>
                                                                                        <b>RTO Name</b>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        {challVal.noMobile}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                        {challVal.nameRTO}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ background: '#ebe9e9' }}>
                                                                                        <b>Remark Challan.</b>
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right", background: '#ebe9e9' }}>
                                                                                        <b>No. of vehicle Owner</b>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        {challVal.remarkChallan}
                                                                                    </div>
                                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                                        {challVal.noVehOwner}
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                            <div style={{ textAlign: 'right', cursor: 'pointer' }} onClick={() => toggleExpanded(index)}>
                                                                                {expandedIndex === index ? <i className="ti ti-square-chevron-up"></i> : <i className="ti ti-square-chevron-down"></i>} {/* Toggle Icon */}
                                                                            </div>
                                                                        </div>    
                                                                       
                                                                    </div>
                                                                </>
                                                            ))}
                                                            </div>
                                                                    
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i> API HEALTH CHECK
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b> Request Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {challanDetails.requestTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Response Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {challanDetails.responseTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Latency(sec)</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {challanDetails.totalTime}
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                             </>
                                            ) : ( '' )}   
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default Challan;