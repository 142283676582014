import React, { useState, useEffect,useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";

const Pan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [pan, setPan] = useState("");
    const [name, setName] = useState("");
    const [dob, setDob] = useState("");
    const [panDetails, setPanDetails] = useState();
    const handleCancel = () => {
        // Reset the form
        formRef.current.reset();
        setPan("");
        setPanDetails(null);
    }

    const panFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "pan_number":pan,
            "name":name,
            "dob":dob,
        };
        CommonService.panSearchNormal(data)
        .then((response) => {
            if(response.data.status === 200){
                console.log(response);
                setPanDetails(response.data.data);
                setIsLoading(false);
            }else{
                
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
                setPanDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data.data);
            if(error.response.data.status === 510){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setPanDetails(null);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.message,
                })
                setIsLoading(false);
                setPanDetails(null);
            }
        });
    }
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">PAN Normal Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ panFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Pan</label>
                                                        <input type="text" defaultValue={pan}
                                                            onChange={(e) => { setPan(e.target.value) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter pan number"
                                                            maxLength={10} 
                                                            />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Name</label>
                                                        <input type="text" defaultValue={name}
                                                            onChange={(e) => { setName(e.target.value) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter name"
                                                            
                                                            />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">DOB</label>
                                                        <input type="date" defaultValue={dob}
                                                            onChange={(e) => { setDob(e.target.value) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter DOb"
                                                           
                                                            />
                                                    </div>
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { panDetails ? (
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-user-check me-1'></i> PAN Details
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>PAN Number</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.pan_number ? panDetails.pan_number : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Pan Holder Title</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.pan_holder_title ? panDetails.pan_holder_title : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>First Name</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.first_name ? panDetails.first_name : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                   <b>Middle Name</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.middle_name ? panDetails.middle_name : 'NA'}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Last Name</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.last_name ? panDetails.last_name : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Date of Birth</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {panDetails.dob ? panDetails.dob : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Name Match</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.name_match ? panDetails.name_match : 'NA'}
                                                                </div>
                                                            </div>
                                                           <div className="row">
                                                                    <div className="col-md-6">
                                                                        <b>DOB Match</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                        {panDetails.dob_match ? panDetails.dob_match : 'NA'}
                                                                    </div>
                                                                </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Aadhaar Seeding Status</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {panDetails.aadhaar_seeding_status_desc ? panDetails.aadhaar_seeding_status_desc : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Name On Card</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {panDetails.name_on_card ? panDetails.name_on_card : 'NA'}
                                                                </div>
                                                            </div>

                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b> Pan Status</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right", color: panDetails.pan_status === 'VALID' ? 'green' : 'red' }}>
                                                                    {panDetails.pan_status ? panDetails.pan_status : 'NA'}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Seeding Status</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.seeding_status ? panDetails.seeding_status : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                <b> Last Updated</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.pan_last_updated ? panDetails.pan_last_updated : 'NA'}
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i> API Status
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <b> Request Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.request_timestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Response Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.response_timestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <b>Latency(sec)</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {panDetails.total_time}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            ) : ( '' )}   
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default Pan;