import { param } from 'jquery';
import { createAxiosInstance, createAxiosInstanceImage } from '../http-common';


const panSearchNormal = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/pan-normal" , params);
};

const panSearchAdvance  = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/pan-advance" , params);
};

const rcSearchNormal = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/rc-normal" , params);
};

const aadharOtp = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/aio-aadhar-otp" , params);
};

const aadharOTPVrification = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/aio-aadhar-verify" , params);
};

const rcSearchAdvance = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/rc-advance" , params);
};

const bankVarification = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/bank-verification" , params);
};

const offlineAdhar = async (params) => {
  const axiosInstance = await createAxiosInstanceImage();
  return axiosInstance.post("/offline-aadhar-verify" , params);
};

const offlinePan = async (params) => {
  const axiosInstance = await createAxiosInstanceImage();
  return axiosInstance.post("/offline-pan" , params);
};

const getDashboard = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/dashboard" , params);
};

const gstSearchNormal = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/gst-normal" , params);
};

const dlSearchNormal = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/driving-licence" , params);
};

const voterIdVrification = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/voterid-advance" , params);
};

const passwordChnage = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/change-password" , params);
};

const forgotPassword = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/forgot-password-email-send" , params);
};

const resetChnage = async (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/forgot-password" , params);
};

const challanSearchAdvance = async  (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/rc-echallan-advance" , params);
}

const rcSearchReverse = async  (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/rc-reverse" , params);
}

const rcSearchHistory = async  (params) => {
  const axiosInstance = await createAxiosInstance();
  return axiosInstance.post("/rc-history" , params);
}


const CommonService = {
    panSearchNormal,
    panSearchAdvance,
    rcSearchNormal,
    rcSearchAdvance,
    passwordChnage,
    forgotPassword,
    resetChnage,
    aadharOtp,
    aadharOTPVrification,
    bankVarification,
    getDashboard,
    offlineAdhar,
    gstSearchNormal,
    dlSearchNormal,
    voterIdVrification,
    offlinePan,
    challanSearchAdvance,
    rcSearchReverse,
    rcSearchHistory
  };
  
  export default CommonService;