import React, { useState, useEffect, useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";

const Gst = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [gst, setGst] = useState("");
    const [gstDetails, setGstDetails] = useState();
    const handleCancel = () => {
        // Reset the form
        formRef.current.reset();
        setGst("");
        setGstDetails(null);
    }

    const panFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "gst_no":gst
        };
        CommonService.gstSearchNormal(data)
        .then((response) => {
            if(response.data.status === 200 && response.data.data.data.status === "success"){
                setGstDetails(response.data.data);
                setIsLoading(false);
            }else{
                console.log('Error', response.data.data.data);
                Swal.fire({
                    icon: 'error',
                    title: response.data.data.data.data.code,
                    text: response.data.data.data.data.message,
                })
                setIsLoading(false);
                setGstDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data.data);
            if(error.response.data.status === 510){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setGstDetails(null);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.message,
                })
                setIsLoading(false);
                setGstDetails(null);
            }
        });
    }
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">GSTIN Normal Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ panFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">GST Num.</label>
                                                        <input type="text" defaultValue={gst}
                                                            onChange={(e) => { setGst(e.target.value) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter gst number"
                                                            maxLength={20} 
                                                            />
                                                    </div>
                                                    
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { gstDetails ? (
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-businessplan me-1'></i> GSTIN DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>GST Number</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.data.GSTIN ? gstDetails.data.data.GSTIN : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Legal Name Of Business</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.data.legal_name_of_business ? gstDetails.data.data.legal_name_of_business : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Trade Name Of Business</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.data.trade_name_of_business ? gstDetails.data.data.trade_name_of_business : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                   <b>Constitution Of Business</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.data.constitution_of_business ? gstDetails.data.data.constitution_of_business : 'NA'}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Center Jurisdiction</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.data.center_jurisdiction ? gstDetails.data.data.center_jurisdiction : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Date of Registration</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {gstDetails.data.data.date_of_registration ? gstDetails.data.data.date_of_registration : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Last Update Date</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.data.last_update_date ? gstDetails.data.data.last_update_date : 'NA'}
                                                                </div>
                                                            </div>
                                                           <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Taxpayer Type</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {gstDetails.data.data.taxpayer_type ? gstDetails.data.data.taxpayer_type : 'NA'}
                                                                </div>
                                                            </div>
                                                           

                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b> GSTIN Status</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right", color: gstDetails.data.data.gst_in_status === 'Active' ? 'green' : 'red' }}>
                                                                    {gstDetails.data.data.gst_in_status ? gstDetails.data.data.gst_in_status : 'NA'}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Message</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.data.message ? gstDetails.data.data.message : 'NA'}
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i>BUSINESS ACTIVITIES
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                        {gstDetails.data.data.nature_of_business_activities.map((element, index) => (
                                                            <div
                                                            className="row"
                                                            style={{ background: index % 2 === 0 ? '#ebe9e9' : 'none' }}
                                                            key={index}
                                                            >
                                                                <div className="col-md-6">
                                                                    <b>{index + 1}</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: 'right' }}>
                                                                    {element}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i>ADDRESS DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Principal Place Address</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {gstDetails.data.data.principal_place_address ? gstDetails.data.data.principal_place_address : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>State Jurisdiction</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {gstDetails.data.data.state_jurisdiction ? gstDetails.data.data.state_jurisdiction : 'NA'}
                                                                </div>
                                                            </div>
                                                            {/* <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                <b> Addtional Address-1</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.data.additional_address_array[0].address}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <b> Addtional Address-2</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.data.additional_address_array[1].address}
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i> API STATUS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <b> Request Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.requestTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Response Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.responseTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <b>Latency(sec)</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {gstDetails.data.totalTime}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            ) : ( '' )}   
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default Gst;